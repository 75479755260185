import { MarkerClusterer, DefaultRenderer } from "@googlemaps/markerclusterer"

window.inbev ||= {}

inbev.location_map =
  api_key: "AIzaSyBLWBv14ltLNWjqcFO454v7Aw0AVLf0yWM"
  map_elements: document.querySelectorAll('.location_map_element')
  styles: [
    {
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#7c6e49"
        }
      ]
    },
    {
      "elementType": "geometry.stroke",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "elementType": "labels",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "elementType": "labels.icon",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#616161"
        },
        {
          "visibility": "off"
        }
      ]
    },
    {
      "elementType": "labels.text.stroke",
      "stylers": [
        {
          "color": "#f5f5f5"
        },
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "administrative",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "administrative.country",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "administrative.land_parcel",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "administrative.land_parcel",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#bdbdbd"
        }
      ]
    },
    {
      "featureType": "administrative.locality",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "administrative.neighborhood",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "administrative.province",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "landscape",
      "stylers": [
        {
          "color": "#7c6e49"
        },
        {
          "lightness": 75
        }
      ]
    },
    {
      "featureType": "poi",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "poi",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "road",
      "stylers": [
        {
          "color": "#e5e5e5"
        }
      ]
    },
    {
      "featureType": "road",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#7c6e49"
        },
        {
          "visibility": "simplified"
        }
      ]
    },
    {
      "featureType": "road.arterial",
      "elementType": "geometry",
      "stylers": [
        {
          "lightness": 65
        }
      ]
    },
    {
      "featureType": "road.highway",
      "elementType": "geometry",
      "stylers": [
        {
          "lightness": 65
        }
      ]
    },
    {
      "featureType": "road.highway",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#616161"
        }
      ]
    },
    {
      "featureType": "road.local",
      "elementType": "geometry",
      "stylers": [
        {
          "lightness": 65
        }
      ]
    },
    {
      "featureType": "transit",
      "stylers": [
        {
          "color": "#7c6e49"
        },
        {
          "lightness": 50
        }
      ]
    },
    {
      "featureType": "water",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#f5f4ef"
        }
      ]
    },
    {
      "featureType": "water",
      "elementType": "labels",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "water",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#9e9e9e"
        }
      ]
    }
  ]

  setup: ->
    if inbev.location_map.map_elements.length == 0
      return false

    !((d, s, id)->
      fjs = d.getElementsByTagName(s)[0]
      if !d.getElementById(id)
        js = d.createElement(s)
        js.id = id
        js.src = "https://maps.googleapis.com/maps/api/js?key=#{inbev.location_map.api_key}&callback=inbev.location_map.initialize"
        fjs.parentNode.insertBefore(js,fjs)
      else
        inbev.location_map.initialize()
    )(document,"script","gmap_loader")

  initialize: ->
    @setup_maps()

  setup_maps: ->
    inbev.location_map.map_elements.forEach (location, idx) ->
      map = inbev.location_map.create_map(location)
      bounds = new google.maps.LatLngBounds()
      markers = []
      infoWindows = []

      document.querySelectorAll('.location_stub_item').forEach (stub, idx) ->
        marker_id = stub.getAttribute('id')
        pin_id = if stub.hasAttribute('data-location-type') then stub.getAttribute('data-location-type') else 'ibhl'
        pin = window.btoa(document.getElementById("map_#{pin_id}").innerHTML)
        marker = new google.maps.Marker({
          position: { lat: parseFloat(stub.getAttribute('data-lat')), lng: parseFloat(stub.getAttribute('data-lng')) }
          icon:
            url: "data:image/svg+xml;base64,#{pin}"
          map: map
        })
        markers.push(marker)

        if stub.querySelector('.location_stub_link')
          content = "<p class='location_map_title'><a href='#{stub.querySelector('.location_stub_link').getAttribute('href')}'>#{stub.querySelector('.article_stub_title').textContent}</a></p><p class='location_map_body'>#{stub.getAttribute('data-location-address')}</p><svg xmlns='http://www.w3.org/2000/svg' width='40' height='17.674' viewBox='0 0 40 17.674'><path d='M0 0c9.814.011 17.767 7.918 17.778 17.674h4.444C22.233 7.918 30.186.011 40 0H0z' fill='#fff' /></svg>"
        else
          content = "<p class='location_map_title'>#{stub.querySelector('.article_stub_title').textContent}</p><p class='location_map_body'>#{stub.getAttribute('data-location-address')}</p><svg xmlns='http://www.w3.org/2000/svg' width='40' height='17.674' viewBox='0 0 40 17.674'><path d='M0 0c9.814.011 17.767 7.918 17.778 17.674h4.444C22.233 7.918 30.186.011 40 0H0z' fill='#fff' /></svg>"
        infoWindow = new google.maps.InfoWindow
          content: content

        infoWindows.push(infoWindow)

        marker.addListener 'click', ->
          infoWindows.forEach (infoWindow) ->
            infoWindow.close()

          infoWindows[idx].open(map, marker)

        bounds.extend(marker.getPosition())

      map.fitBounds(bounds)

      renderer = render: (_ref) ->
        count = _ref.count
        position = _ref.position
        svg = window.btoa("<svg xmlns='http://www.w3.org/2000/svg' width='40' height='40' viewBox='0 0 40 40'><style>@import url('https://use.typekit.net/hkt5xuq.css');</style><circle fill='#00704A' fill-opacity='0.9' cx='20' cy='20' r='20' /><text x='50%' y='50%' text-anchor='middle' dominant-baseline='middle' font-family='franklin-gothic-atf,arial,sans-serif' style='fill:#fff' font-weight='500' font-size='12'>#{count}</text></svg>")
        new (google.maps.Marker)(
          icon:
            url: "data:image/svg+xml;base64,#{svg}"
            anchor: new google.maps.Point(25, 25)
          position: position
          zIndex: Number(google.maps.Marker.MAX_ZINDEX) + count)

      markerCluster = new MarkerClusterer({ map, markers, renderer })

  create_map: (location) ->
    options =
      center: new google.maps.LatLng(location.getAttribute('data-lat'), location.getAttribute('data-lng'))
      zoom: 12
      mapTypeId: google.maps.MapTypeId.ROADMAP
      draggable: true
      zoomControl: false
      mapTypeControl: false
      streetViewControl: false
      fullscreenControl: false
      styles: inbev.location_map.styles

    if window.innerWidth > 849
      options['zoomControl'] = true

    new google.maps.Map(location, options)
