window.inbev ||= {}

inbev.product_block =
  setup: ->
    @setup_blocks()

  setup_blocks: ->
    blocks = document.querySelectorAll(".product_content_block.animate")
    return false unless blocks.length > 0

    blocks.forEach (block, idx) ->
      tl = gsap.timeline({
        scrollTrigger: {
          trigger: block,
          start: "top 75%"
        }
      })

      tl.fromTo(block, { y: 50, opacity: 0 }, { y: 0, opacity: 1, duration: 1})
      

