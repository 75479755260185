window.inbev ||= {}

inbev.home_video_block =
  setup: ->
    @setup_blocks()

  setup_blocks: ->
    blocks = document.querySelectorAll(".home_video_block_content_block")
    return false unless blocks.length > 0

    blocks.forEach (block) ->
      tl = gsap.timeline({
        scrollTrigger: {
          trigger: block,
          start: "top top",
          end: "+=200%"
          scrub: 1,
          pin: true
        }
      })

      if window.matchMedia('(max-width: 599px)').matches
        inset_y = '56px'
        inset_x = '6%'

      else if window.matchMedia('(max-width: 849px)').matches
        inset_y = '64px'
        inset_x = '11%'

      else if window.matchMedia('(max-width: 1023px)').matches
        inset_y = '96px'
        inset_x = '96px'

      else if window.matchMedia('(max-width: 1279px)').matches
        inset_y = '104px'
        inset_x = '104px'

      else
        inset_y = '120px'
        inset_x = '120px'

      tl.to(block, {opacity: 1, duration: 1})
      tl.to(block, {clipPath: "inset(#{inset_y} #{inset_x} round 4px)"})
      tl.from(block.querySelector('.home_video_block_content'), {y: '50vh', opacity: 0})
      tl.to(block.querySelector('.home_video_block_content'), {opacity: 1, duration: 1})
