import "./vendor/gsap.min.js";
import "./vendor/ScrollTrigger.min.js";
import "./tictoc/environment.js.erb";
import "./tictoc/preview.js";
import "./tictoc/ui.js";
import "./inbev/cookie_notice.js.coffee";
import "./inbev/menu.js.coffee";
import "./inbev/custom_checkbox.js.coffee";
import "./inbev/custom_select.js.coffee";
import "./inbev/location_map.js.coffee";
import "./inbev/block_map.js.coffee";
import "./inbev/article_filter.js.coffee";
import "./inbev/video.js.coffee";
import "./inbev/age_gate.js.coffee";
import "./inbev/article.js.coffee";
import "./inbev/product_block.js.coffee";
import "./inbev/intro_block.js.coffee";
import "./inbev/brand_block.js.coffee";
import "./inbev/home_video_block.js.coffee";
import "./inbev/gallery.js.coffee";

var ready;

ready = function (callback) {
  if (document.readyState !== "loading") {
    callback();
  } else if (document.addEventListener) {
    document.addEventListener("DOMContentLoaded", callback);
  } else {
    document.attachEvent("onreadystatechange", function () {
      if (document.readyState === "complete") {
        callback();
      }
    });
  }
};

ready(function () {
  tictoc.preview.setup();
  tictoc.ui.setup();
  inbev.cookie_notice.setup();
  inbev.menu.setup();
  inbev.custom_checkbox.setup();
  inbev.custom_select.setup();
  inbev.location_map.setup();
  inbev.block_map.setup();
  inbev.article_filter.setup();
  inbev.video.setup();
  inbev.age_gate.setup();
  inbev.article.setup();
  inbev.product_block.setup();
  inbev.gallery.setup();
  inbev.intro_block.setup();
  inbev.brand_block.setup();
  inbev.home_video_block.setup();
});
