window.inbev ||= {}

inbev.article =
  setup: ->
    @setup_stubs()

  setup_stubs: ->
    stubs = document.querySelectorAll(".article_stub_item.animate:not(.hide_on_listing)")
    return false unless stubs.length > 0

    console.log stubs

    stubs.forEach (stub, idx) ->
      tl = gsap.timeline({
        scrollTrigger: {
          trigger: stub,
          start: "top 75%"
        }
      })

      if window.matchMedia('(min-width: 850px)').matches
        if idx % 2
          tl.fromTo(stub.querySelector('img'),{ x: '10%', opacity: 0 }, { x: 0, opacity: 1, duration: 1})
        else
          tl.fromTo(stub.querySelector('img'),{ x: '-10%', opacity: 0 }, { x: 0, opacity: 1, duration: 1})
        
        tl.fromTo(stub.querySelector('.article_stub_title.animate'),{ opacity: 0 }, { opacity: 1, duration: 1})

      else
        tl.fromTo(stub,{ y: 50, opacity: 0 }, { y: 0, opacity: 1, duration: 1})