class CustomSelect
  constructor: (@select) ->
    @select_wrapper = document.createElement('div')
    @select_wrapper.classList.add('custom_select_wrapper')

    @select.parentElement.insertBefore(@select_wrapper, @select)
    @select_wrapper.appendChild(@select)

    @update_wrapper_value()
    @add_listeners()

  add_listeners: ->
    @select.addEventListener 'change', =>
      @update_wrapper_value(@select, @select_wrapper)

    @select.addEventListener 'focus', =>
      @select_wrapper.setAttribute('data-focus', true)
    
    @select.addEventListener 'blur', =>
      @select_wrapper.removeAttribute('data-focus')

    @select.addEventListener 'mouseenter', =>
      @select_wrapper.setAttribute('data-hover', true)
    
    @select.addEventListener 'mouseleave', =>
      @select_wrapper.removeAttribute('data-hover')

  update_wrapper_value: ->
    select_value = @select.options[@select.selectedIndex].text
    select_value ||= ''
    @select_wrapper.setAttribute('data-value', select_value)

window.inbev ||= {}

window.inbev.custom_select =
  setup: ->
    document.querySelectorAll('select').forEach (select) ->
      custom_select = new CustomSelect(select)
