window.inbev ||= {}

inbev.article_filter =
  setup: ->
    @setup_form_submit()
    @setup_tag_in_view()
  
  setup_form_submit: ->
    article_filters = document.querySelectorAll('.tag_filter select')
    return unless article_filters.length

    article_filters.forEach (filter) ->
      filter.addEventListener 'change', (e) ->
        e.target.closest('form').submit()
  
  setup_tag_in_view: ->
    active_tag = document.querySelectorAll('#tag_list .active')

    return false unless active_tag.length

    active_tag_parent = active_tag[0].parentNode
    tag_list_location = (active_tag_parent.getBoundingClientRect().left + (active_tag_parent.offsetWidth / 2) - (window.innerWidth / 2))
    document.querySelector('#tag_list').scrollTo(tag_list_location, 0)

