window.inbev ||= {}

inbev.brand_block =
  setup: ->
    @setup_blocks()

  setup_blocks: ->
    blocks = document.querySelectorAll(".brand_block_content_block")
    return false unless blocks.length > 0

    blocks.forEach (block) ->
      tl1 = gsap.timeline({
        scrollTrigger: {
          trigger: block,
          start: "top 50%",
          end: "top top"
          scrub: 1
        }
      })
      
      tl2 = gsap.timeline({
        scrollTrigger: {
          trigger: block,
          start: "top top",
          end: "+=150%"
          scrub: 1,
          pin: true
        }
      })

      tl1.from(block.querySelector('.brand_block_container > svg'), {scale: 0, opacity: 0}, 0)

      block.querySelectorAll('img').forEach (img, idx) ->
        tl1.from(img, {top: '50%', left: '50%', x: '-50%', opacity: 0.5}, 0)
        # tl1.fromTo(img, {opacity: 0}, {opacity: 1})
      
      
      tl2.fromTo(block.querySelector('.brand_block_content'), {opacity: 0}, {opacity: 1})
      tl2.to(block.querySelector('.brand_block_content'), {opacity: 1, duration: 1})
