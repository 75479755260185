class Gallery
  constructor: (gallery) ->
    @gallery = gallery
    @gallery_items = gallery.querySelectorAll('.gallery_block_item')
    @gallery_container = gallery.querySelector('.gallery_block_container')
    @gallery_next_link = gallery.querySelector('.gallery_block_next_link')
    @gallery_previous_link = gallery.querySelector('.gallery_block_prev_link')
    @distance_to_move = 0
    @initial_distance_to_move = 0

    @clone_originals()
    @setup_initial_values()
    @add_listeners()

  setup_initial_values: ->
    @gallery_items[0].setAttribute('data-current-item', true)
    @gallery_items[0].classList.add('current')

  add_listeners: ->
    clicked = false
    touched = false

    if @gallery_next_link
      @gallery_next_link.addEventListener 'click', (e) =>
        e.preventDefault()

        if !clicked
          clicked = true
          current_item_index = @return_current_item()
          @change_item(current_item_index, current_item_index + 1)

          setTimeout ->
            clicked = false
          , 1000

    if @gallery_previous_link
      @gallery_previous_link.addEventListener 'click', (e) =>
        e.preventDefault()

        if !clicked
          clicked = true
          current_item_index = @return_current_item()
          @change_item(current_item_index, current_item_index - 1)

          setTimeout ->
            clicked = false
          , 1000

    @gallery_container.addEventListener 'touchstart', (e) =>
      e.preventDefault()

      if !touched
        touched = true
        current_item_index = @return_current_item()
        gallery_x_start = e.changedTouches[0].pageX

        @gallery_container.addEventListener 'touchmove', (e) =>
          e.preventDefault()
        , passive: false

        @gallery_container.addEventListener 'touchend', (e) =>
          e.preventDefault()
          gallery_x_end = e.changedTouches[0].pageX

          if ((gallery_x_start - gallery_x_end) > 50)
            @change_item(@current_item_index, @current_item_index + 1)

          else if ((gallery_x_end - gallery_x_start) > 50)
            @change_item(@current_item_index, @current_item_index - 1)

          @gallery_container.removeEventListener 'touchmove', (arguments.callee)
          @gallery_container.removeEventListener 'touchend', (arguments.callee)

          setTimeout ->
            touched = false
          , 1000

  change_item: (@current_item_index, @new_item_index) ->

    @gallery_items.forEach (item) =>
      item.removeAttribute('data-current-item')
      item.classList.remove('current')

    if @new_item_index < 0
      cloned_item = @all_gallery_items[1]
      cloned_item.classList.add('current')
      @new_item_index = @gallery_items.length - 1
      @gallery_items[@new_item_index].setAttribute('data-current-item', true)
      @gallery_items[@new_item_index].classList.add('current')

      @distance_to_move = @distance_to_move - ((@gallery_items[@current_item_index].getBoundingClientRect().width + @gallery_items[@new_item_index].getBoundingClientRect().width) / 2)

      tl = gsap.timeline()

      tl.to(@all_gallery_items, {x: ((@initial_distance_to_move + @distance_to_move) * -1), ease: "power2.inOut", duration: 1})

      @distance_to_move = 0
      @gallery_items.forEach (item, idx) =>
        unless idx == @gallery_items.length - 1
          @distance_to_move = @distance_to_move + ((@gallery_items[idx].getBoundingClientRect().width + @gallery_items[idx + 1].getBoundingClientRect().width) / 2)

      tl.set(@all_gallery_items, {x: ((@initial_distance_to_move + @distance_to_move) * -1)})

      setTimeout ->
        cloned_item.classList.remove('current')
      , 1000

    else if @new_item_index >= @gallery_items.length
      cloned_item = @all_gallery_items[@all_gallery_items.length - 2]
      cloned_item.classList.add('current')
      
      @new_item_index = 0
      @gallery_items[@new_item_index].setAttribute('data-current-item', true)
      @gallery_items[@new_item_index].classList.add('current')
      @distance_to_move = @distance_to_move + ((@gallery_items[@current_item_index].getBoundingClientRect().width + @gallery_items[@new_item_index].getBoundingClientRect().width) / 2)

      tl = gsap.timeline()

      tl.to(@all_gallery_items, {x: ((@initial_distance_to_move + @distance_to_move) * -1), ease: "power2.inOut", duration: 1})
      @distance_to_move = 0
      tl.set(@all_gallery_items, {x: @initial_distance_to_move * -1})

      setTimeout ->
        cloned_item.classList.remove('current')
      , 1000

    else
      if @new_item_index > @current_item_index
        @gallery_items[@new_item_index].setAttribute('data-current-item', true)
        @gallery_items[@new_item_index].classList.add('current')

        @distance_to_move = @distance_to_move + ((@gallery_items[@current_item_index].getBoundingClientRect().width + @gallery_items[@new_item_index].getBoundingClientRect().width) / 2)

        gsap.to(@all_gallery_items, {x: ((@initial_distance_to_move + @distance_to_move) * -1), ease: "power2.inOut", duration: 1})

      else
        @gallery_items[@new_item_index].setAttribute('data-current-item', true)
        @gallery_items[@new_item_index].classList.add('current')

        @distance_to_move = @distance_to_move - ((@gallery_items[@current_item_index].getBoundingClientRect().width + @gallery_items[@new_item_index].getBoundingClientRect().width) / 2)

        gsap.to(@all_gallery_items, {x: ((@initial_distance_to_move + @distance_to_move) * -1), ease: "power2.inOut", duration: 1})

    @update_count()

  update_count: ->
    @gallery.querySelector('.gallery_block_count .current_item').textContent = @new_item_index + 1
  
  return_current_item: ->
    return Array.from(@gallery_items).findIndex((item) -> item.hasAttribute('data-current-item'))

  clone_originals: =>
    @first_two_gallery_items = Array.from(@gallery_items).slice(0, 2)
    @last_two_gallery_items = Array.from(@gallery_items).slice(-2).reverse()

    @first_two_gallery_items.forEach (item) =>
      @gallery_container.appendChild(item.cloneNode(true))

    @last_two_gallery_items.forEach (item, idx) =>
      @gallery_container.insertBefore(item.cloneNode(true), @gallery_container.firstChild)

      if @last_two_gallery_items.length == 2
        if idx == 0
          @initial_distance_to_move = @initial_distance_to_move + item.getBoundingClientRect().width

        else
          @initial_distance_to_move = @initial_distance_to_move + ((item.getBoundingClientRect().width + @gallery_items[0].getBoundingClientRect().width) / 2)

      else
        @initial_distance_to_move = @initial_distance_to_move + ((item.getBoundingClientRect().width + @gallery_items[0].getBoundingClientRect().width) / 2)

    @all_gallery_items = @gallery.querySelectorAll('.gallery_block_item')
    gsap.set(@all_gallery_items, {x: @initial_distance_to_move * -1})

window.inbev ||= {}

inbev.gallery =
  setup: ->
    document.querySelectorAll('.gallery_block_content_block').forEach (gallery) ->
      gallery = new Gallery(gallery)