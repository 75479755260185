window.inbev ||= {}

inbev.intro_block =
  setup: ->
    @setup_blocks()

  setup_blocks: ->
    blocks = document.querySelectorAll(".intro_block_content_block")
    return false unless blocks.length > 0

    blocks.forEach (block) ->
      tl1 = gsap.timeline({
        scrollTrigger: {
          trigger: block,
          start: "top 75%",
          end: "top top"
          scrub: 1
        }
      })

      tl2 = gsap.timeline({
        scrollTrigger: {
          trigger: block,
          start: "top top",
          end: "+=150%"
          scrub: 1,
          pin: true
        }
      })

      
      block.querySelectorAll('img').forEach (img, idx) ->
        tl1.fromTo(img, {x: '-50%', opacity: 0}, {x: 0, opacity: 1}, idx*0.2)
      
      tl2.fromTo(block.querySelector('h2'), {x: '50%', opacity: 0}, {x: 0, opacity: 1})
      tl2.to(block.querySelector('h2'), {opacity: 1, duration: 1})